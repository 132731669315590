<template>
  <authorization-gate
    auth-route="/reports"
    auth-type="exec"
    class="pb-3 my-auto mx-3"
  >
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="click"
          class="text--secondary px-6 pa-2"
          :disabled="disabled"
          :loading="loading"
          :color="color || 'default'"
        >
          <v-icon>
            mdi-table-arrow-down
          </v-icon>
        </v-btn>
      </template>

      <span> {{ label || "Gerar Relatório" }} </span>
    </v-tooltip>
  </authorization-gate>
</template>

<script>
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import Report from "./domain/Report";

export default {
  name: "GenerateReportsButton",
  components: {
    AuthorizationGate
  },
  props: {
    table: String,
    label: String,
    filter: String,
    filename: String,
    url: String,
    color: String
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    click() {
      const options = {};

      if (this.url) {
        options.url = this.url;
      }

      const generateReport = Report.generateReport.bind(this);

      generateReport(options);
    },
    buildQuery() {
      const filters = [];

      for (const item in this.filter) {
        if (item == "name_or_cnpj_cpf") {
          const cnpj = [];

          for (const payer of this.filter[item]) {
            cnpj.push(payer.cnpj_cpf);
          }

          filters["cnpj_cpf"] = cnpj;
        } else {
          filters[item] = this.filter[item];
        }
      }

      const query = {
        params: {
          filter: {
            ...filters,
            report: this.table,
            extension: "csv",
            filename: this.filename,
            socket_id: window.Echo.socketId()
          }
        }
      };

      return query;
    }
  }
};
</script>

<style></style>
