<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="pagination.itemsPerPage"
      hide-default-footer
      loading-text="Carregando dados, aguarde..."
      class="elevation-0"
      :loading="loading"
    >
      <template #[`item.invoice`]="{ item }">
        {{ item.invoice.invoice || "Unificado" }}
      </template>

      <template #[`item.value`]="{ item }">
        BRL {{ formatedCurrency(item.value) }}
      </template>

      <template #[`item.result_total`]="{ item }">
        BRL {{ formatedCurrency(item.invoice.result_total) }}
      </template>

      <template #[`item.approving_user`]="{ item }">
        {{ item.approving_user ? item.approving_user.name : "" }}
      </template>

      <template #[`item.date`]="{ item }">
        {{ item.created_at_br_pattern }}
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip
          class="ma-2 white--text red"
          v-show="item.status == discountApprovalStatus.DISAPPROVED"
        >
          Reprovado
        </v-chip>

        <v-chip
          class="ma-2 white--text green"
          v-show="item.status == discountApprovalStatus.APPROVED"
        >
          Aprovado
        </v-chip>

        <v-chip
          class="ma-2 white--text grey darken-2"
          v-show="item.status == discountApprovalStatus.PENDING"
        >
          Pendente
        </v-chip>
      </template>

      <template v-slot:item.action="{ item }">
        <v-col cols="12" class="py-2">
          <v-btn-toggle>
            <v-btn
              x-small
              fab
              :value="discountApprovalStatus.APPROVED"
              v-show="item.status == discountApprovalStatus.PENDING"
              @click="
                handleChangeDiscountApprovalStatus(
                  discountApprovalStatus.APPROVED,
                  item
                )
              "
              color="success"
            >
              <v-icon center>
                mdi-check
              </v-icon>
            </v-btn>

            <v-btn
              x-small
              fab
              :value="discountApprovalStatus.DISAPPROVED"
              v-show="item.status == discountApprovalStatus.PENDING"
              @click="
                handleChangeDiscountApprovalStatus(
                  discountApprovalStatus.DISAPPROVED,
                  item
                )
              "
              color="error"
            >
              <v-icon center>
                mdi-close
              </v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </template>

      <template v-slot:item.detail="{ item }">
        <v-col cols="12" class="py-2">
          <v-btn-toggle>
            <v-btn
              x-small
              fab
              elevation="1"
              @click="dialogDetails = { opened: true, approval: item }"
            >
              <v-icon center>
                mdi-eye-outline
              </v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDetails.opened" max-width="800">
      <v-card>
        <v-card-title class="headline">Justificativa e Arquivos</v-card-title>

        <v-card-text>
          <!-- Campo de Justificativa -->
          <v-textarea
            outlined
            label="Justificativa"
            v-if="
              dialogDetails.approval && dialogDetails.approval.justification
            "
            v-model="dialogDetails.approval.justification"
            rows="5"
            :readonly="true"
            style="max-height: 150px; overflow-y: auto !important;"
          ></v-textarea>

          <!-- Tabela de Arquivos -->
          <v-simple-table
            dense
            class="mt-4"
            v-if="dialogDetails.approval && dialogDetails.approval.files"
          >
            <thead>
              <tr>
                <th class="text-left">Nome do Arquivo</th>
                <th class="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(file, index) in dialogDetails.approval.files"
                :key="index"
              >
                <td>{{ file.file_name }}</td>
                <td class="text-right">
                  <v-btn icon small :href="file.download_url">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <!-- Botões de Ação -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDetails.opened = false"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import DiscountApprovalStatus from "@/pages/DiscountApprovals/enums/DiscountApprovalStatus";
import DiscountApprovalService from "@/app/Services/DiscountApprovalService";
import UserTypes from "@/enums/UserTypes";
import { bus } from "@/main";

export default {
  name: "DiscountApprovalsTable",
  props: {
    items: Array,
    pagination: Object,
    loading: Boolean
  },
  created() {
    if (this.user.user_role_id != UserTypes.ADMINISTRATOR) {
      const key = this.headers.findIndex(
        header => header.value == "approving_user"
      );

      if (key > -1) {
        delete this.headers[key];
      }
    }
  },
  data() {
    return {
      discountApprovalStatus: DiscountApprovalStatus,
      userTypes: UserTypes,
      headers: [
        {
          text: "Fatura",
          align: "center",
          sortable: true,
          value: "invoice"
        },
        {
          text: "Cliente",
          align: "center",
          sortable: true,
          value: "invoice.payer.name"
        },
        {
          text: "Desconto",
          align: "start",
          sortable: true,
          value: "value"
        },
        {
          text: "Total",
          align: "start",
          sortable: true,
          value: "result_total"
        },
        {
          text: "Aprovador",
          align: "center",
          sortable: true,
          value: "approving_user"
        },
        {
          text: "Data",
          align: "center",
          sortable: true,
          value: "date"
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Ações",
          sortable: false,
          value: "action",
          width: "80",
          align: "center"
        },
        {
          text: "",
          sortable: false,
          value: "detail",
          width: "10",
          align: "center"
        }
      ],
      authorization: {
        route: "/discount-types"
      },
      params: [],
      dialogDetails: {
        opened: false
      }
    };
  },
  mixins: [AuthorizationMixin],
  methods: {
    formatedCurrency(item, options) {
      const value = this.withoutSymbolCurrency(item, options);

      const intValue = `${value}`.replace("-", "");

      return intValue;
    },
    handleChangeDiscountApprovalStatus(status, approval) {
      if (status != approval.status) {
        const data = {
          id: approval.id,
          status: status
        };
        let strStatus = "";
        let artStatus = "info";

        switch (status) {
          case DiscountApprovalStatus.APPROVED:
            strStatus = "APROVAR";
            artStatus = "success";
            break;
          case DiscountApprovalStatus.DISAPPROVED:
            strStatus = "REPROVAR";
            artStatus = "error";
            break;
        }

        bus.$emit("showNotificationDialog", {
          status: artStatus,
          title: `Deseja ${strStatus} o desconto da fatura: ${approval.invoice
            .invoice || "Unificado"}`,
          continue: async () => {
            try {
              this.loading = true;

              await DiscountApprovalService.build().update(data);

              this.showToast({
                message: "Status alterado com sucesso"
              });

              bus.$emit("update-discount-approval-list");
            } catch (error) {
              console.error(error);
              this.defaultCatchError(error);
            } finally {
              this.loading = false;
            }
          }
        });
      }
    }
  },
  computed: {
    user() {
      const storage = localStorage.getItem("user");

      if (storage !== null) {
        const parsedStorage = JSON.parse(storage);

        return parsedStorage.user;
      }

      return null;
    }
  }
};
</script>
