var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length > 0)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":true,"header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type == "unified" ? "Unificado" : item.invoice)+" ")]}},{key:"item.payer_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payer_name ? item.payer_name : "Não Encontrado")+" ")]}},{key:"item.actual_currency_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" BRL "+_vm._s(_vm._f("financialCurrencyWithoutSymbol")((item.actual_currency_value ? item.actual_currency_value : 0)))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.value, { maximumFractionDigits: 4, minimumFractionDigits: 4 }))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatISODate(item.created_at, { withHour: true }))+" ")]}},{key:"item.fine",fn:function(ref){
var item = ref.item;
return [_vm._v(" BRL "+_vm._s(_vm._f("financialCurrencyWithoutSymbol")(_vm.getFineAfter10DaysValue(item)))+" ")]}},{key:"item.result_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")((item.result_total ? item.result_total : 0)))+" ")]}}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }