<template>
  <v-data-table
    v-if="items.length > 0"
    :headers="headers"
    :items="items"
    :items-per-page="pagination.itemsPerPage"
    :hide-default-footer="true"
    :header-props="{ sortIcon: null }"
    class="elevation-0"
  >
    <template #[`item.invoice`]="{ item }">
      {{ item.type == "unified" ? "Unificado" : item.invoice }}
    </template>

    <template #[`item.payer_name`]="{ item }">
      {{ item.payer_name ? item.payer_name : "Não Encontrado" }}
    </template>

    <template #[`item.actual_currency_value`]="{ item }">
      BRL
      {{
        (item.actual_currency_value ? item.actual_currency_value : 0)
          | financialCurrencyWithoutSymbol
      }}
    </template>

    <template #[`item.value`]="{ item }">
      {{ item.currency ? item.currency : "BRL" }}
      {{ (item.total ? item.total : 0) | financialCurrencyWithoutSymbol }}
    </template>

    <template #[`item.created_at`]="{ item }">
      {{ formatISODate(item.created_at, { withHour: true }) }}
    </template>

    <template #[`item.fine`]="{ item }">
      BRL {{ getFineAfter10DaysValue(item) | financialCurrencyWithoutSymbol }}
    </template>

    <template #[`item.value`]="{ item }">
      {{
        toCurrency(item.value, {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4
        })
      }}
    </template>

    <template #[`item.result_total`]="{ item }">
      {{ (item.result_total ? item.result_total : 0) | toCurrency }}
    </template>
  </v-data-table>
</template>

<script>
import InvoiceVerify from "@/pages/Invoice/domain/InvoiceVerify";

const invoiceVerify = new InvoiceVerify();

export default {
  name: "DiscountTable",
  props: {
    items: Array,
    pagination: Object
  },
  data() {
    return {
      headers: [
        { text: "Fatura", value: "invoice" },
        { text: "Pagador", value: "payer_name" },
        { text: "Valor da Fatura", value: "value" },
        {
          text: "Taxa ",
          value: "actual_currency_value"
        },
        { text: "Valor do Desconto", value: "value" },
        {
          text: "Multa de 10 Dias",
          value: "fine"
        },
        { text: "Valor Final", value: "result_total" },
        { text: "Data de Concessão", value: "created_at" }
      ]
    };
  },
  methods: {
    getInvoiceTotal(item) {
      if (!item.invoice) {
        return 0;
      }

      return invoiceVerify.getTotalWithDiscounts(item.invoice);
    },
    getFineAfter10DaysValue(item) {
      if (!item.invoice) {
        return 0;
      }

      if (!item.result_charges) {
        return 0;
      }

      return item.fine;
    }
  }
};
</script>

<style></style>
