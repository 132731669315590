var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":"","loading-text":"Carregando dados, aguarde...","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoice.invoice || "Unificado")+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" BRL "+_vm._s(_vm.formatedCurrency(item.value))+" ")]}},{key:"item.result_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" BRL "+_vm._s(_vm.formatedCurrency(item.invoice.result_total))+" ")]}},{key:"item.approving_user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.approving_user ? item.approving_user.name : "")+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at_br_pattern)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.status == _vm.discountApprovalStatus.DISAPPROVED),expression:"item.status == discountApprovalStatus.DISAPPROVED"}],staticClass:"ma-2 white--text red"},[_vm._v(" Reprovado ")]),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.status == _vm.discountApprovalStatus.APPROVED),expression:"item.status == discountApprovalStatus.APPROVED"}],staticClass:"ma-2 white--text green"},[_vm._v(" Aprovado ")]),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.status == _vm.discountApprovalStatus.PENDING),expression:"item.status == discountApprovalStatus.PENDING"}],staticClass:"ma-2 white--text grey darken-2"},[_vm._v(" Pendente ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('v-btn-toggle',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.status == _vm.discountApprovalStatus.PENDING),expression:"item.status == discountApprovalStatus.PENDING"}],attrs:{"x-small":"","fab":"","value":_vm.discountApprovalStatus.APPROVED,"color":"success"},on:{"click":function($event){return _vm.handleChangeDiscountApprovalStatus(
                _vm.discountApprovalStatus.APPROVED,
                item
              )}}},[_c('v-icon',{attrs:{"center":""}},[_vm._v(" mdi-check ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.status == _vm.discountApprovalStatus.PENDING),expression:"item.status == discountApprovalStatus.PENDING"}],attrs:{"x-small":"","fab":"","value":_vm.discountApprovalStatus.DISAPPROVED,"color":"error"},on:{"click":function($event){return _vm.handleChangeDiscountApprovalStatus(
                _vm.discountApprovalStatus.DISAPPROVED,
                item
              )}}},[_c('v-icon',{attrs:{"center":""}},[_vm._v(" mdi-close ")])],1)],1)],1)]}},{key:"item.detail",fn:function(ref){
              var item = ref.item;
return [_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('v-btn-toggle',[_c('v-btn',{attrs:{"x-small":"","fab":"","elevation":"1"},on:{"click":function($event){_vm.dialogDetails = { opened: true, approval: item }}}},[_c('v-icon',{attrs:{"center":""}},[_vm._v(" mdi-eye-outline ")])],1)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialogDetails.opened),callback:function ($$v) {_vm.$set(_vm.dialogDetails, "opened", $$v)},expression:"dialogDetails.opened"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Justificativa e Arquivos")]),_c('v-card-text',[(
            _vm.dialogDetails.approval && _vm.dialogDetails.approval.justification
          )?_c('v-textarea',{staticStyle:{"max-height":"150px","overflow-y":"auto !important"},attrs:{"outlined":"","label":"Justificativa","rows":"5","readonly":true},model:{value:(_vm.dialogDetails.approval.justification),callback:function ($$v) {_vm.$set(_vm.dialogDetails.approval, "justification", $$v)},expression:"dialogDetails.approval.justification"}}):_vm._e(),(_vm.dialogDetails.approval && _vm.dialogDetails.approval.files)?_c('v-simple-table',{staticClass:"mt-4",attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Nome do Arquivo")]),_c('th',{staticClass:"text-right"},[_vm._v("Ações")])])]),_c('tbody',_vm._l((_vm.dialogDetails.approval.files),function(file,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(file.file_name))]),_c('td',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":"","small":"","href":file.download_url}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)])}),0)]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogDetails.opened = false}}},[_vm._v("Fechar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }