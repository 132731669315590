export default {
  data() {
    return {
      instructions: {
        search_for: [],
        filter_for: []
      },
      filter: {},
      searchFunction: "loadData"
    };
  },
  methods: {
    search(filter) {
      this.pagination.page = 1;

      this.filter = filter || {};

      this["loadData"]();
    }
  }
};
